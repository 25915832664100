<template>
  <!-- <router-view></router-view> -->
  <div></div>
</template>

<script>
import Vue from "vue";
// import Navbar from './components/Navbar';
// import Footer from './components/footer';
import VueMeta from "vue-meta";

Vue.use(VueMeta, { refreshOnceOnNavigation: true });

export default {
  name: "App",

  components: {
    // Navbar,
    // Footer,
  },

  data: () => ({
    //
  }),

  beforeCreate() {
    window.location.href = "https://www.figorr.com/";
  },

  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: "Gricd.com",
    // all titles will be injected into this template
    titleTemplate: "%s - Get The Best IoT Cold Chain Monitoring Solutions",
    link: [
      // {rel: 'stylesheet', href: '/css/index.css'},
      // {
      //   rel: 'stylesheet',
      //   href:
      //     'https://cdn.jsdelivr.net/npm/@mdi/font@latest/css/materialdesignicons.min.css',
      // },
      // {rel: 'favicon', href: 'favicon.png'},
    ],
  },
};
</script>
<style lang="scss">
body,
html {
  overflow-x: hidden;
}
.search input {
  background: white;
  width: 100%;
  padding: 13px 10px;
}
.search input:focus-visible {
  outline: 0;
}
.search-icon {
  position: absolute;
  right: 3rem;
  top: 40%;
}

@keyframes slide-up {
  from {
    margin-top: 100%;
    height: 300%;
  }

  to {
    margin-top: 0%;
    height: 100%;
  }
}

@keyframes scrollLeft {
  from {
    right: 0;
  }
  to {
    right: 100%;
  }
}

.scroll-left {
  animation: 8s scrollLeft;
  -webkit-animation: 8s scrollLeft;
}

.slide-up {
  animation: 1s slide-up;
  -webkit-animation: 1s slide-up;
  margin-top: 0%;
}

// @keyframes rotation {
//   from {
//     transform: 'rotateY(0deg)';
//   }
//   to {
//     transform: 'rotateY(360deg)';
//   }
// }
// .rotate {
//   animation: 'rotation 8s infinite linear';
// }

@media (min-width: 576px) {
  .heading-title {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  // .line {
  //   border-bottom: solid 3px;
  //   width: 2rem;
  //   position: relative;
  //   left: 6px;
  //   margin-bottom: 7px;
  // }
  // .line2 {
  //   position: relative;
  //   right: 10px;
  //   width: 3rem;
  //   border-bottom: solid 3px;
  // }
}

@keyframes example {
  0% {
    right: 0%;
  }
  50% {
    right: 100%;
  }
  to {
    right: 0%;
  }
}

#container__ span {
  position: relative;
  display: flex;
  flex-wrap: no-wrap;
  animation: 30s example infinite 3s linear;
}
</style>
