<template>
  <div>
    <v-row
      class=""
      style="
background: #28A84A;position:relative"
      :class="$vuetify.breakpoint.xs ? 'mb-4' : 'px-12 video-row'"
    >
      <v-col
        cols="12"
        sm="8"
        class="left-caro"
        :class="$vuetify.breakpoint.xs ? 'pb-16' : 'py-16'"
        :style="
          $vuetify.breakpoint.xs
            ? 'position:relative; padding: 3rem 1.5rem !important;'
            : 'position:absolute'
        "
      >
        <v-carousel
          interval="10000"
          hide-delimiters
          height="auto"
          cycle
          :show-arrows="false"
          :show-arrows-on-hover="$vuetify.breakpoint.xs ? true : false"
        >
          <v-carousel-item v-for="(color, i) in colors" :key="i">
            <!-- <span
              style="position:relative"
              :style="
                !$vuetify.breakpoint.smAndDown ? ';margin-left: -2rem;' : ''
              "
            >
              <p
                v-if="color.name"
                class="body-1 one-img mt-6"
                :class="color.name === 'Logistics' ? 'black--text' : ''"
                style="margin:0"
              >
                {{ color.name }}
              </p>
              <img :src="color.no" alt="" />
            </span> -->
            <h1
              class="white--text mb-8"
              :style="$vuetify.breakpoint.xs ? 'width:100%' : 'width:75%'"
            >
              {{ color.heading }}
            </h1>
          </v-carousel-item>
        </v-carousel>
        <p
          class="white--text"
          :style="
            $vuetify.breakpoint.xs
              ? 'width:100%'
              : 'width: 69%;position: relative;top: 1.5rem;'
          "
          style="margn-bottom:'24px'"
        >
          The Grid mote is a real-time monitoring device, designed to help
          organizations in the health, food, dairy, and Agro-industry set up and
          manage effective and scalable storage and transportation system.
        </p>
        <v-btn
          :class="
            $vuetify.breakpoint.xs ? 'px-8 mt-4' : 'py-5 rounded-pill first-btn'
          "
          rounded
          color="white"
          to="/contact/demo-request"
          class="top-btn"
          style="width:fit-content; color:#28A84A; font-weight:bolder"
          >SCHEDULE A FREE DEMO</v-btn
        >
      </v-col>
      <v-col
        cols="12"
        sm="8"
        class="pa-0 left-caro right-caro"
        :style="
          $vuetify.breakpoint.xs ? 'position:relative' : 'position:absolute'
        "
      >
        <span :class="$vuetify.breakpoint.mdAndDown ? '' : 'triangle'">
          <video
            autoplay
            loop
            playsinline
            controls
            muted
            poster=""
            width="100%"
            height="100%"
            style="object-fit: contain"
          >
            <source
              src="https://res.cloudinary.com/dcjbgkkej/video/upload/v1631627567/IMG_4723_wr27mw.mp4"
              type="video/mp4"
            />
            <!-- <source src="../assets/video/IMG_4723.mp4" type="video/mp4" /> -->
            Your browser does not support HTML video.
          </video>
          <!-- <img
            src="../assets/video/gifvid.gif"
            alt=""
            height="100%"
            width="100%"
          /> -->
        </span>
      </v-col>
    </v-row>

    <div style="background: #F4F6F7;">
      <v-row>
        <v-col class="pb-0">
          <!-- <div class="section-3">
            <div
              class="section-head"
              :class="$vuetify.breakpoint.xs ? 'mb-8' : ''"
            >
              <div class="heading-title">
                <span>
                  <div class="line"></div>
                  <div class="line2"></div>
                </span>
                <h2 :class="$vuetify.breakpoint.xs ? 'py-4' : ''">
                  Our Products
                </h2>
              </div>
              <p>
                The hardware products are used in monitoring
                temperature-sensitive products <br />ranging from agriculture to
                pharmaceutical products.
              </p>
            </div>
            <v-row
              class="btm-half"
              :class="$vuetify.breakpoint.mdAndDown ? 'pa-4 py-10' : 'pa-16'"
              style="justify-content:space-evenly"
            >
              <div v-for="(card, i) in cards" :key="i">
                <Card
                  :title="card.title"
                  :content="card.content"
                  :img="card.img"
                  :link="card.link"
                ></Card>
                <v-spacer></v-spacer>
              </div>
            </v-row>
          </div> -->

          <div
            class="section-3b "
            :class="$vuetify.breakpoint.xs ? 'pb-10' : ''"
          >
            <div class="slide-up">
              <div class="section-head mb-10">
                <div class="heading-title">
                  <span>
                    <div class="line"></div>
                    <div class="line2" style="margin-bottom:14px"></div>
                  </span>

                  <h2 class="pb-4">Enterprise Software</h2>
                </div>
                <!-- <p>
                The software monitors your product while in storage or transit,
                making sure you <br />
                are always aware of your product quality. Our monitoring system
                sends instant <br />
                notifications of temperature excursions.
              </p> -->
                <p>
                  We are an IoT company that provides real-time monitoring for
                  temperature-sensitive products. <br />
                  The software provides:
                </p>
              </div>
            </div>
            <v-row class="rec-card-cont">
              <v-col cols="12" sm="5">
                <v-item-group mandatory>
                  <div v-for="(m, i) in images" :key="i + '1'" class="items">
                    <v-item v-slot="{ active, toggle }">
                      <div
                        class="rec-card mb-4"
                        @click="onCardChange(m.img), toggle()"
                        style="cursor:pointer"
                      >
                        <div class="left">
                          <h4 class="pb-4">{{ m.name }}</h4>
                          <p>
                            {{ m.description }}
                          </p>
                        </div>
                        <div v-if="active" class="circle">
                          <!-- <div class="arrow"></div> -->
                          <v-icon class="icon_" color="white" medium
                            >mdi-chevron-right</v-icon
                          >
                        </div>
                      </div>
                    </v-item>
                  </div>
                </v-item-group>
              </v-col>
              <v-col cols="12" sm="7">
                <img alt="" :src="currentImg" class="dash-img lazy" />
                <!-- <picture>
                  <source :srcset="currentImgw" type="image/webp" />
                  <source :srcset="currentImg" type="image/jpeg" />
                  <img alt="" :src="currentImg" class="dash-img lazy" />
                </picture> -->
              </v-col>
            </v-row>
          </div>
          <div>
            <v-carousel
              v-model="model"
              interval="10000"
              hide-delimiters
              show-arrows-on-hover
              height="auto"
              cycle
            >
              <v-carousel-item v-for="(item, i) in bottomSlide" :key="i + '2'">
                <v-sheet color="#1E1E2D" height="100%" class="py-8" tile>
                  <div class="heading-title">
                    <span>
                      <div class="line"></div>
                      <div class="line2"></div>
                    </span>
                    <h2
                      :class="$vuetify.breakpoint.xs ? 'text-center' : ''"
                      style="color: #28A84A;"
                    >
                      Applications
                    </h2>
                  </div>
                  <v-row
                    :style="
                      $vuetify.breakpoint.xs
                        ? '    flex-flow: column-reverse;display: flex;'
                        : ''
                    "
                  >
                    <v-col class="black-left" cols="12" sm="6">
                      <p
                        :class="$vuetify.breakpoint.xs ? 'mb-4' : 'mb-4'"
                        style="color:#28A84A"
                      >
                        {{ item.no }}
                      </p>
                      <h4 :class="$vuetify.breakpoint.xs ? 'mb-4' : 'mb-4'">
                        {{ item.name }}
                      </h4>
                      <p class="white--text" style="font-weight: 300;">
                        {{ item.content }}
                      </p>
                      <router-link :to="item.link">
                        <p class="mt-8" style="color:#28A84A">READ MORE</p>
                      </router-link>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      class="black-right d-flex align-center"
                    >
                      <img
                        :src="item.src"
                        alt=""
                        width="100%"
                        class="lazy"
                        height="100%"
                      />
                    </v-col>
                  </v-row>
                </v-sheet>
              </v-carousel-item>
            </v-carousel>
          </div>
          <div>
            <div style="position:relative">
              <img
                src="../assets/SVG/Group 1934 (1).svg"
                alt="partners"
                width="100%"
                :style="$vuetify.breakpoint.xs ? 'height:15rem' : 'height:auto'"
              />
              <div
                class="pt-12"
                style="height: 7rem; position: absolute;top: 0px;width: 100%;"
              >
                <div class="heading-title">
                  <span class="stroke">
                    <div class="line"></div>
                    <div class="line2"></div>
                  </span>
                  <h2
                    style="color:#28A84A; font-size:34px"
                    class="text-center"
                    :class="$vuetify.breakpoint.mdAndDown ? 'mb-6' : ''"
                  >
                    Our Clients
                  </h2>
                </div>

                <v-row
                  style="flex-flow: row;overflow-x: auto;"
                  class="scroll"
                  id="container__"
                  :class="$vuetify.breakpoint.mdAndDown ? 'mx-4' : 'mx-16'"
                >
                  <span>
                    <img
                      height="auto"
                      width="100%"
                      :class="'lazy'"
                      alt=""
                      v-for="(m, i) in clientImg"
                      :key="i + '3'"
                      :src="m.img"
                      :style="
                        $vuetify.breakpoint.mdAndDown
                          ? 'width: 8rem;height: 8rem; object-fit:contain'
                          : 'width: 10rem;height: 10rem; object-fit:contain'
                      "
                      class="mx-2"
                    />
                  </span>
                </v-row>
              </div>
            </div>
            <div class="popular">
              <div class="first"></div>
              <div class="second"></div>
              <div class="pop">
                <div class="section-head popular head">
                  <div class="heading-title">
                    <span>
                      <div class="line"></div>
                      <div class="line2"></div>
                    </span>
                    <h2
                      style="color:#28A84A; font-size:34px"
                      :class="$vuetify.breakpoint.xs ? 'text-center' : ''"
                    >
                      Popular Stories
                    </h2>
                  </div>
                </div>
                <v-row
                  :class="$vuetify.breakpoint.xs ? 'justify-center' : ''"
                  :style="
                    $vuetify.breakpoint.mdAndDown
                      ? 'padding:0 1rem; justify-content:space-between'
                      : 'padding:0 1rem; justify-content:space-evenly'
                  "
                >
                  <div v-for="(m, i) in lastCard" :key="i + '4'">
                    <v-hover v-slot="{ hover }">
                      <div
                        outlined
                        class=" mb-10 card-cont"
                        max-width="400"
                        style="max-width:400px;"
                        :style="
                          hover
                            ? 'box-shadow: 0px 20px 50px 0px rgba(84, 143, 188, 0.1);'
                            : ''
                        "
                      >
                        <v-img :src="m.img" height="100%">
                          <div class="green-cont">
                            <p
                              class="body1 white--text mb-0"
                              style="font-weight:bold; font-size: 24px;"
                            >
                              {{ m.date }}
                            </p>
                            <p
                              class="body1 white--text mb-0"
                              style="font-size: 20px;"
                            >
                              {{ m.month }}
                            </p>
                          </div>
                        </v-img>

                        <v-card-subtitle class="card-sub">
                          <img
                            :src="icon1"
                            alt=""
                            class="lazy"
                            style="position:relative; top: 4px;"
                          />
                          {{ m.title }}
                        </v-card-subtitle>

                        <v-card-title class="v-title-card">
                          {{ m.content }}
                        </v-card-title>

                        <router-link :to="m.link" style="text-decoration:none">
                          <v-card-actions>
                            <p
                              color="black"
                              style="font-weight: 300; font-size:0.75rem !important; padding-left:4%"
                              text
                              :style="hover ? 'color:#28A84A' : ''"
                            >
                              continue reading
                              <v-icon
                                x-small
                                class="ml-1"
                                :style="hover ? 'color:#28A84A' : ''"
                                >mdi-arrow-right</v-icon
                              >
                            </p>
                          </v-card-actions>
                        </router-link>
                        <v-spacer></v-spacer>
                      </div>
                    </v-hover>
                  </div>
                </v-row>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
    </div>
    <div
      style="position:relative; margin-top:0.9rem"
      :style="$vuetify.breakpoint.smAndup ? 'margin-top:0.9rem' : ''"
    >
      <img
        src="../assets/SVG/Group 1917.svg"
        alt="partners"
        width="100%"
        height="auto"
        :style="$vuetify.breakpoint.xs ? 'height:40rem' : 'height:30rem'"
      />
      <div
        class="pt-6"
        style="height: 7rem; position: absolute;top: 0px;width: 100%;"
      >
        <div class="heading-title">
          <span class="stroke">
            <div class="line"></div>
            <div class="line2"></div>
          </span>
          <h2
            style="color:#28A84A; font-size:34px"
            class="text-center"
            :class="$vuetify.breakpoint.mdAndDown ? 'mb-6' : ''"
          >
            We Also Featured On
          </h2>
        </div>

        <v-row
          style="flex-flow: row;"
          class="scroll"
          :class="$vuetify.breakpoint.mdAndDown ? '' : 'mx-16'"
        >
          <v-col cols="12">
            <v-row
              class="featured py-3"
              :style="
                $vuetify.breakpoint.mdAndDown
                  ? 'justify-content:space-evenly'
                  : 'justify-content:center; width:100%'
              "
              style=" overflow-x:scoll"
            >
              <img
                :src="m.img"
                alt=""
                v-for="(m, i) in top"
                :key="i + '5'"
                @click="goLink(m.link)"
                :style="
                  $vuetify.breakpoint.mdAndDown
                    ? 'width: 6rem;height: 6rem; object-fit:contain'
                    : 'width: 9rem;height: 6rem; object-fit:contain'
                "
                style="cursor:pointer"
                v-show="!$vuetify.breakpoint.xs"
                class="mx-4 lazy"
              />
              <img
                :src="m.img"
                :class="'lazy'"
                alt=""
                v-for="(m, i) in mobileTop"
                :key="i + '6'"
                @click="goLink(m.link)"
                :style="
                  $vuetify.breakpoint.mdAndDown
                    ? 'width: 6rem;height: 6rem; object-fit:contain'
                    : 'width: 9rem;height: 6rem; object-fit:contain'
                "
                style="cursor:pointer"
                v-show="$vuetify.breakpoint.xs"
              />
            </v-row>
            <v-row
              class="featured py-3"
              :style="
                $vuetify.breakpoint.mdAndDown
                  ? 'justify-content:space-evenly'
                  : 'justify-content:center; width:100%'
              "
              style=" overflow-x:scoll"
            >
              <img
                :src="m.img"
                :class="'lazy'"
                alt=""
                v-for="(m, i) in mid"
                :key="i + '7'"
                @click="goLink(m.link)"
                :style="
                  $vuetify.breakpoint.mdAndDown
                    ? 'width: 6rem;height: 6rem; object-fit:contain'
                    : 'width: 9rem;height: 6rem; object-fit:contain'
                "
                style="cursor:pointer"
                v-show="!$vuetify.breakpoint.xs"
                class="mx-4"
              />
              <img
                :src="m.img"
                :class="'lazy'"
                alt=""
                v-for="(m, i) in mobileSec"
                :key="i + '8'"
                @click="goLink(m.link)"
                :style="
                  $vuetify.breakpoint.mdAndDown
                    ? 'width: 6rem;height: 6rem; object-fit:contain'
                    : 'width: 9rem;height: 6rem; object-fit:contain'
                "
                style="cursor:pointer"
                v-show="$vuetify.breakpoint.xs"
              />
            </v-row>
            <v-row
              v-show="$vuetify.breakpoint.xs"
              class="featured py-3"
              :style="
                $vuetify.breakpoint.mdAndDown
                  ? 'justify-content:space-evenly'
                  : 'justify-content:center; width:100%'
              "
              style=" overflow-x:scoll"
            >
              <img
                :src="m.img"
                :class="'lazy'"
                alt=""
                v-for="(m, i) in mobileThird"
                :key="i + '9'"
                @click="goLink(m.link)"
                :style="
                  $vuetify.breakpoint.mdAndDown
                    ? 'width: 6rem;height: 6rem; object-fit:contain'
                    : 'width: 9rem;height: 6rem; object-fit:contain'
                "
                style="cursor:pointer"
                v-show="$vuetify.breakpoint.xs"
              />
            </v-row>
            <v-row
              class="featured py-3"
              :style="
                $vuetify.breakpoint.mdAndDown
                  ? 'justify-content:space-evenly'
                  : 'justify-content:center; width:100%'
              "
              style=" overflow-x:scoll"
            >
              <img
                :src="m.img"
                :class="'lazy'"
                alt=""
                v-for="(m, i) in last"
                :key="i + '10'"
                @click="goLink(m.link)"
                :style="
                  $vuetify.breakpoint.mdAndDown
                    ? 'width: 6rem;height: 6rem; object-fit:contain'
                    : 'width: 9rem;height: 6rem; object-fit:contain'
                "
                style="cursor:pointer"
                v-show="!$vuetify.breakpoint.xs"
                class="mx-4"
              />
              <img
                :src="m.img"
                :class="'lazy'"
                alt=""
                v-for="(m, i) in mobileLast"
                :key="i + '11'"
                @click="goLink(m.link)"
                :style="
                  $vuetify.breakpoint.mdAndDown
                    ? 'width: 6rem;height: 6rem; object-fit:contain'
                    : 'width: 9rem;height: 6rem; object-fit:contain'
                "
                style="cursor:pointer"
                v-show="$vuetify.breakpoint.xs"
              />
            </v-row>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
import "material-design-icons-iconfont/dist/material-design-icons.css";
import img1 from "../assets/images/Rectangle 1212-compressed.jpg";
// import img2 from '../assets/images/Image from iOS.jpg';
// import img3 from '../assets/images/Rectangle 1212 (5)-compressed.jpg';
// import img4 from '../assets/images/Rectangle 1212 (6)-compressed.jpg';
// import no1 from '../assets/images/01.png';
// import no2 from '../assets/images/02.png';
// import no3 from '../assets/images/03.png';

import cl1 from "../assets/clients/image 25.svg";
import cl2 from "../assets/clients/image 26.svg";
import cl3 from "../assets/clients/image 28.svg";
import cl4 from "../assets/clients/image 30.svg";
import cl5 from "../assets/clients/image 44.svg";
import cl6 from "../assets/clients/image 43.svg";
import cl7 from "../assets/clients/image 42.svg";
import cl8 from "../assets/clients/image 41.svg";
import cl9 from "../assets/clients/image 38.svg";
import cl10 from "../assets/clients/image 36.svg";
import cl11 from "../assets/clients/image 31.svg";
import cl12 from "../assets/clients/image 35.svg";
import cl13 from "../assets/clients/image 34.svg";
// import cl14 from '../assets/clients/image 33.svg';
import cl15 from "../assets/clients/nphcda_small_logo.jpeg";
import cl16 from "../assets/clients/Vaccipharm Logo.png";
import cl17 from "../assets/clients/mPharma .jpeg";
import cl18 from "../assets/clients/ISN logo.jpeg";

// import image1 from '../assets/images/image.png';
import image2 from "../assets/images/image (1).png";
import image3 from "../assets/images/image (2).png";
import image4 from "../assets/images/image (7).png";
import image5 from "../assets/images/image (8).png";
import image6 from "../assets/images/image (9).png";
import dashboard from "../assets/images/dashboard 4 (1) 1 (1).jpg";
import dashboardwebp from "../assets/images/dashboard-4-_1_-1-_1_.webp";
import device from "../assets/images/device 4 (1) 1@3x.png";
import group from "../assets/images/group 4 (1) 1@3x.png";
import map from "../assets/images/map 4 (1) 1@3x.png";
import record from "../assets/images/last 4 (1) 1@3x.png";
import partners1 from "../assets/images/Group 1946.png";
import icon1 from "../assets/icons/Group (1).png";
// import Card from '../components/Card';
import firstImg from "../assets/images/Rectangle 1221.png";
import secImg from "../assets/images/Rectangle 1221 2.png";
import thrdImg from "../assets/images/Rectangle 1233-compressed.jpg";

import cnn from "../assets/SVG/cnn.svg";
import point from "../assets/SVG/techpoint (1).svg";
import day from "../assets/SVG/this day.svg";
import disrupt from "../assets/SVG/Discrupt.svg";
import arise from "../assets/SVG/arise.svg";
import tech from "../assets/SVG/Techcabal.svg";
import yaho from "../assets/SVG/Yahoo.svg";
import biz from "../assets/SVG/bussiness (1).svg";
// import quartz from '../assets/featured/Quartz.png';
import smear from "../assets/SVG/smears.svg";
// import teke from '../assets/featured/Quartz (2).png';
import technext from "../assets/SVG/technext.svg";
// import saur from '../assets/featured/arise (2).png';
// import nog from '../assets/featured/arise (1).png';
import channel from "../assets/SVG/channels.svg";
// import techBuild from '../assets/featured/techpoint (2).png';
// import africaTech from '../assets/featured/Yahoo (1).png';
import ngrHealth from "../assets/SVG/naijahealth.svg";
// import muni from '../assets/featured/africaMuni.png';
// import {LazyYoutube} from 'vue-lazytube';
// import LazyYoutubeVideo from 'vue-lazy-youtube-video';

// import smearM from '../assets/featured/Mobile/bussiness (4).png';

export default {
  name: "Home",
  components: {
    // Card,
    // LazyYoutubeVideo,
    // LazyYoutube,
    // LazyVimeo,
  },
  data: () => {
    return {
      img1,
      currentImg: dashboard,
      currentImgw: dashboardwebp,
      images: [
        {
          name: "Dashboard",
          img: dashboard,
          imgw: dashboardwebp,
          id: 1,
          description:
            " A quick overview of activities that occur in your account",
        },
        {
          name: "My Devices",
          img: device,
          id: 2,
          description:
            "Displays devices status and help users keep track with any changes in device temperature.",
        },
        {
          name: "Groups",
          img: group,
          id: 3,
          description:
            "Collection of devices that can be managed and monitored as a single unit",
        },
        {
          name: "Live Map",
          img: map,
          id: 4,
          description:
            "Enable you track your device in real-time, it gives you location of your device.",
        },
        {
          name: "Records",
          img: record,
          id: 5,
          description: "Helps you schedule recording for future time",
        },
      ],
      lastCard: [
        {
          img: image4,
          content: "Curbing Losses In The Food Value Chain In Africa",
          title: "Agriculture",
          link: "/blog/curbing-losses-in-the-food-value-chain-in-africa",
          date: "29",
          month: "APR",
        },
        {
          img: image5,
          content: "Technology Firm Introduces Healthcare Product",
          title: "Healthcare",
          link: "/blog/technology-firm-introduces-healthcare-product",
          date: "01",
          month: "APR",
        },
        {
          img: image6,
          content:
            "The Brilliance Of Nigeria’s Gricd Frij On Eliminating Food And Drug Wastes",
          title: "Logistics",
          link:
            "/blog/the-brilliance-of-nigeria’s-gricd-frij-on-eliminating-food-and-drug-wastes",
          date: "07",
          month: "MAR",
        },
      ],
      partners1,
      icon1,
      model: 0,
      bottomSlide: [
        {
          name: "Agriculture",
          no: "01",
          content:
            " Most of our agricultural perishable products are temperature sensitive and require to be kept within the cold chain from the moment they are harvested to when they are stored, transported, and distributed to the final consumer.",
          src: firstImg,
          link: "/cold-chain-application/agriculture",
        },
        {
          name: "Healthcare",
          no: "02",
          content:
            " The issue of temperature excursions is crucial to pharmaceutical companies since many of their products require temperature-controlled storage and distribution to maintain their efficacy and other properties.",
          src: secImg,
          link: "/cold-chain-application/healthcare",
        },
        {
          name: "Logistics",
          no: "03",
          content:
            "Temperature-sensitive products can suffer from inadequate shipping conditions. Gricd provides a solution to ensure across the entire supply chain that all products are optimally stored and shipped.",
          src: thrdImg,
          link: "/cold-chain-application/logistics",
        },
      ],
      colors: [
        {
          src: img1,
          name: "Healthcare",
          content:
            "We build IoT powered autonomous cold boxes to store, transport and prevent waste of vaccines and other temperature sensitive medication.",
          button: "Discover how",
          // no: no1,
          heading: "Get the best cold chain monitoring devices.",
          to: "/about/our-story",
        },
        {
          name: "Logistic",
          content:
            "With our cold boxes, we handle last mile cold chain logistics, maintaining items at precise regulated temperature.. ",
          button: "REQUEST A TRIP",
          heading:
            "Reduce the cost of operation with real-time data-backed decisions.",
          click: true,
        },
        // {
        //   name: 'Agriculture',
        //   content:
        //     'We offer affordable cold chain logistics rate to income farmers to curb post harvest losses and increase their earning',
        //   button: 'DISCOVER HOW',
        //   heading:
        //     'Reduce the risk of product and specimen waste with technology-enabled storage and logistic solution.',
        //   to: '/about/our-story',
        // },
        {
          name: "Agriculture",
          content:
            "We offer affordable cold chain logistics rate to income farmers to curb post harvest losses and increase their earning",
          button: "DISCOVER HOW",
          heading:
            "Maintain the potency of Covid-19 vaccines in storage and in transit ",
          to: "/about/our-story",
        },
        {
          name: "Agriculture",
          content:
            "We offer affordable cold chain logistics rate to income farmers to curb post harvest losses and increase their earning",
          button: "DISCOVER HOW",
          heading: "Scale your Operation and increase profitability",
          to: "/about/our-story",
        },
        // {src: img2},
      ],
      cards: [
        // {
        //   img: image1,
        //   title: 'Gricd ColdBox 15',
        //   content:
        //     'The Gricd Cold Box (mobile refrigerator) is a reusable all-in-one storage tracker for cold chain environmental products monitoring.......',
        // },
        {
          img: image2,
          title: "Gricd Mote",
          content:
            "Gricd mote is battery-powered and can be stick on any smooth surface, our sensing solutions are adaptable to outdoor and indoor applications.......",
          link: "/cold-chain-equipments/gricd-mote",
        },
        {
          img: image3,
          title: "Gricd Coldbox 50",
          content:
            "The Gricd Cold Box (mobile refrigerator) is a reusable all-in-one storage tracker for cold chain environmental products monitoring.......",
          link: "/cold-chain-equipments/mote-50",
        },
      ],
      clientImg: [
        { img: cl1 },
        { img: cl2 },
        { img: cl3 },
        { img: cl4 },
        { img: cl5 },
        { img: cl6 },
        { img: cl7 },
        { img: cl8 },
        { img: cl9 },
        { img: cl10 },
        { img: cl15 },
        { img: cl16 },
        { img: cl17 },
        { img: cl18 },
        { img: cl11 },
        { img: cl12 },
        { img: cl13 },
        // {img: cl14},
      ],

      mobileTop: [
        // {
        //   img: smearM,
        //   link:
        //     'https://www.stearsng.com/premium/article/the-story-of-gricd-a-storage-solution-company',
        // },
        // {
        //   img: smearM,
        //   link:
        //     'https://www.stearsng.com/premium/article/the-story-of-gricd-a-storage-solution-company',
        // },
        // {
        //   img: smearM,
        //   link:
        //     'https://www.stearsng.com/premium/article/the-story-of-gricd-a-storage-solution-company',
        // },
        {
          img: smear,
          link:
            "https://www.stearsng.com/premium/article/the-story-of-gricd-a-storage-solution-company",
        },
        {
          img: biz,
          link:
            "https://businessday.ng/events/article/gricd-wins-greenhouse-lab-demo-day-2019/",
        },
        // {
        //   img: teke,
        //   link:
        //     'https://www.tekedia.com/the-brilliance-of-nigerias-gricd-frij-on-eliminating-food-and-drug-wastes/',
        // },
        {
          img: day,
          link:
            "https://www.thisdaylive.com/index.php/2019/04/01/technology-firm-introduces-healthcare-product/",
        },
      ],
      mobileSec: [
        {
          img: tech,
          link:
            "https://techcabal.com/2019/11/13/my-life-in-tech-at-28-oghenetega-iortim-is-solving-africas-coldchain-logistics-problem/",
        },
        {
          img: technext,
          link:
            "https://technext.ng/2019/09/12/meet-gricd-frij-startup-using-iot-to-provide-cold-chain-solutions-for-the-agric-and-health-sector/",
        },
        {
          img: yaho,
          link:
            "https://finance.yahoo.com/news/sun-power-large-parts-africa-144011246.html",
        },
      ],
      mobileThird: [
        //   {
        //     img: smearM,
        //     link:
        //       'https://www.stearsng.com/premium/article/the-story-of-gricd-a-storage-solution-company',
        //   },
        //   {
        //     img: smearM,
        //     link:
        //       'https://www.stearsng.com/premium/article/the-story-of-gricd-a-storage-solution-company',
        //   },
        //   {
        //     img: smearM,
        //     link:
        //       'https://www.stearsng.com/premium/article/the-story-of-gricd-a-storage-solution-company',
        //   },
        {
          img: cnn,
          link:
            "https://edition.cnn.com/2021/01/14/africa/gallery/coronavirus-innovations-africa-spc-intl/index.html",
        },
        {
          img: disrupt,
          link:
            "https://disrupt-africa.com/2019/12/17/cold-storage-startup-gricd-wins-greenhouse-lab-demo-day/",
        },
        {
          img: channel,
          link: "https://www.youtube.com/watch?v=qXCJ-yhACKM&t=8s",
        },
      ],
      mobileLast: [
        // {
        //   img: smearM,
        //   link:
        //     'https://www.stearsng.com/premium/article/the-story-of-gricd-a-storage-solution-company',
        // },
        // {
        //   img: smearM,
        //   link:
        //     'https://www.stearsng.com/premium/article/the-story-of-gricd-a-storage-solution-company',
        // },
        // {
        //   img: smearM,
        //   link:
        //     'https://www.stearsng.com/premium/article/the-story-of-gricd-a-storage-solution-company',
        // },
        {
          img: ngrHealth,
          link:
            "https://nigeriahealthwatch.com/a-chill-on-disease-gricd-frij-a-homegrown-solution-to-nigerias-cold-chain-constraints/",
        },
        {
          img: point,
          link: "https://techpoint.africa/2019/06/11/techcircle-2019-finalist/",
        },
        { img: arise, link: "https://www.youtube.com/watch?v=gZi0ufs1QrQ" },
      ],

      top: [
        {
          img: smear,
          link:
            "https://www.stearsng.com/premium/article/the-story-of-gricd-a-storage-solution-company",
        },
        {
          img: biz,
          link:
            "https://businessday.ng/events/article/gricd-wins-greenhouse-lab-demo-day-2019/",
        },
        // {
        //   img: teke,
        //   link:
        //     'https://www.tekedia.com/the-brilliance-of-nigerias-gricd-frij-on-eliminating-food-and-drug-wastes/',
        // },
        {
          img: day,
          link:
            "https://www.thisdaylive.com/index.php/2019/04/01/technology-firm-introduces-healthcare-product/",
        },
        {
          img: tech,
          link:
            "https://techcabal.com/2019/11/13/my-life-in-tech-at-28-oghenetega-iortim-is-solving-africas-coldchain-logistics-problem/",
        },
        {
          img: technext,
          link:
            "https://technext.ng/2019/09/12/meet-gricd-frij-startup-using-iot-to-provide-cold-chain-solutions-for-the-agric-and-health-sector/",
        },
      ],
      mid: [
        // {
        //   img: saur,
        //   link:
        //     'https://www.saurenergy.com/solar-energy-news/will-solar-powered-refrigeration-come-of-age-in-vaccination-push',
        // },
        {
          img: yaho,
          link:
            "https://finance.yahoo.com/news/sun-power-large-parts-africa-144011246.html",
        },
        // {
        //   img: nog,
        //   link: 'https://blog.nogtech.ng/2020/11/27/gricd-feature-nogtech/',
        // },
        {
          img: cnn,
          link:
            "https://edition.cnn.com/2021/01/14/africa/gallery/coronavirus-innovations-africa-spc-intl/index.html",
        },
        {
          img: disrupt,
          link:
            "https://disrupt-africa.com/2019/12/17/cold-storage-startup-gricd-wins-greenhouse-lab-demo-day/",
        },
        {
          img: channel,
          link: "https://www.youtube.com/watch?v=qXCJ-yhACKM&t=8s",
        },
      ],
      // bottom: [
      //   {
      //     img: techBuild,
      //     link:
      //       'https://techbuild.africa/gricd-logistics-problem-agriculture-healthcare/',
      //   },
      //   {
      //     img: yaho,
      //     link:
      //       'https://finance.yahoo.com/news/sun-power-large-parts-africa-144011246.html',
      //   },
      //   {
      //     img: africaTech,
      //     link:
      //       'https://tmt.knect365.com/africacom/african-entrepreneurship-startup-gricd/',
      //   },
      //   {
      //     img: muni,
      //     link:
      //       'https://amsp.africa/product/gricd-mote-real-time-data-logger-for-cold-chain/',
      //   },
      // ],
      last: [
        {
          img: ngrHealth,
          link:
            "https://nigeriahealthwatch.com/a-chill-on-disease-gricd-frij-a-homegrown-solution-to-nigerias-cold-chain-constraints/",
        },
        {
          img: point,
          link: "https://techpoint.africa/2019/06/11/techcircle-2019-finalist/",
        },
        { img: arise, link: "https://www.youtube.com/watch?v=gZi0ufs1QrQ" },
      ],
      // videos: [
      //   // {
      //   //   url: 'https://www.youtube.com/embed/4JS70KB9GS0',
      //   //   previewImageSize: 'maxresdefault',
      //   // },
      //   // {
      //   //   url: 'https://www.youtube.com/embed/65MVwN_Kz1Q',
      //   //   previewImageSize: 'hqdefault',
      //   // },
      //   {
      //     url: 'https://www.youtube.com/embed/KbX1gYtPVYE',
      //     previewImageSize: 'sddefault',
      //     aspectRatio: '1:1',
      //   },
      //   // {
      //   //   url: 'https://www.youtube.com/embed/etKOc80-cw0',
      //   //   previewImageSize: 'mqdefault',
      //   // },
      // ],
      videoID: "some-id",
      height: 500,
      options: {
        muted: true,
        autoplay: true,
      },
      playerReady: false,
      youtubeLink: "https://www.youtube.com/watch?v=qXCJ-yhACKM&t=8s",
      // youtubeLink: 'https://drive.google.com/file/d/1vAR7OX5zlBVDr3SFBf3AACjgvNgZFEzU',
      // vimeoLink: 'https://player.vimeo.com/video/64654583',
    };
  },
  methods: {
    onCardChange(e) {
      this.currentImg = e;
    },
    goLink: (e) => {
      window.open(e, "_blank").focus();
    },
    onReady() {
      this.playerReady = true;
    },
    play() {
      this.$refs.player.play();
    },
    pause() {
      this.$refs.player.pause();
    },
    goTrip() {
      location.href = "http://go.Gricd.com";
    },

    // lazyLoad: function() {
    //   let lazyImages = [].slice.call(document.querySelectorAll('img.lazy'));
    //   let active = false;
    //   if (active === false) {
    //     active = true;
    //     setTimeout(() => {
    //       lazyImages.forEach(function(lazyImage) {
    //         if (
    //           lazyImage.getBoundingClientRect().top <= window.innerHeight &&
    //           lazyImage.getBoundingClientRect().bottom >= 0 &&
    //           getComputedStyle(lazyImage).display !== 'none'
    //         ) {
    //           lazyImage.src = lazyImage.dataset.src;
    //           lazyImage.classList.remove('lazy');
    //           lazyImages = lazyImages.filter(function(image) {
    //             return image !== lazyImage;
    //           });

    //           if (lazyImages.length === 0) {
    //             window.removeEventListener('scroll', this.lazyLoad);
    //           }
    //         }
    //       });
    //       active = false;
    //     }, 200);
    //   }
    // },
  },

  mounted() {
    // window.scrollTo(0, 0);
    // window.addEventListener('scroll', this.lazyLoad);
    // let ckeditor = document.createElement('script');
    // ckeditor.setAttribute('src', '../tags.html');
    // document.head.appendChild(ckeditor);
  },
};
</script>

<style lang="scss" scoped>
.img-class {
  background-image: url("../assets/images/Rectangle 1212-compressed.jpg");
}
.fill-height h1 {
  font-size: 2.4rem;
  width: 50%;
  @media (max-width: 1024px) {
    width: inherit;
    font-size: 35px;
    margin-bottom: 15px;
  }
}
.fill-height p {
  font-size: 1.2rem;
  color: white;
  width: 50%;
  @media (max-width: 1024px) {
    width: 85%;
    font-size: 17px;
  }
}
.section-2 {
  padding: 5rem 6rem;
  background: white;
  @media (max-width: 768px) {
    padding: 3rem 1rem !important;
  }
  @media (max-width: 1290px) {
    padding: 5rem 6rem 2rem;
  }
}
.section-2 p {
  font-size: 16px;
  line-height: 180%;
  text-align: center;
  color: #212529;
  margin-top: 2rem;
}
p {
  font-size: 16px;
  line-height: 180%;
  color: #212529;
}
.section-2 h2 {
  font-weight: bold;
  font-size: 34px;
  line-height: 140%;
  color: #28a84a;
  text-align: center;
}
.section-2 h2:before {
  border: solid 3px black;
  /* content: ''; */
  width: 3rem;
  position: absolute;
  right: 58%;
  top: 36%;
  height: 15px;
  z-index: 1;
  border-left: 0;
  border-right: 0;
  border-radius: 1px;
}
.section-3 {
  background: linear-gradient(
    0deg,
    rgba(229, 235, 237, 0.13) 1.51%,
    rgba(236, 254, 239, 0.24) 45.29%,
    #ecfeef 127.23%
  );
  /* background: linear-gradient(
    182.38deg,
    #ffffff 25.21%,
    rgba(230, 236, 238, 0.46) 51.49%,
    rgba(242, 245, 247, 0.46) 104.62%
  ); */
}
.section-head {
  height: 15rem;
  padding: 5rem 5rem;
  @media (max-width: 769px) {
    height: 14rem !important;
    padding: 2rem 1rem !important;
  }
  @media (max-width: 1290px) {
    // padding: 3rem 5rem;
    // height: 10rem;
    padding: 2.5rem 5rem 3rem;
  }
}
.section-head p {
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 180%;
  text-align: center;
  color: #212529;
}
.section-3 h2 {
  font-weight: bold;
  font-size: 34px;
  line-height: 140%;
  color: #28a84a;
  text-align: center;
}
.prod-img {
  padding: 3rem 6rem 4rem;
}
.section-3b {
  // background: linear-gradient(
  //   180deg,
  //   rgba(229, 235, 237, 0.11) 3%,
  //   #e5ebed 46.3%,
  //   #e5ebed 80.15%,
  //   #ecfeef 124.06%
  // );
}
.section-3b h2 {
  font-weight: bold;
  font-size: 34px;
  line-height: 140%;
  color: #28a84a;
  text-align: center;
}
.circle {
  clip-path: circle(50% at 50% 50%);
  background: #28a84a;
  height: 34px;
  width: 34px;
  @media (max-width: 1024px) {
    height: 25px;
    width: 25px;
  }
}
.arrow {
  background: #ffffff;
  transform: matrix(0, 1, 1, 0, 0, 0);
}
.rec-card {
  width: 100%;
  padding: 1rem;
  background: #ffffff;
  border-radius: 5px;
  display: flex;
  align-items: center;
  min-height: 7rem;
  @media (max-width: 768px) {
    // // min-height: 0;
    // width: 50%;
    // // p {
    // //   display: none;
    // // }
    // // .pb-4{
    // //   padding-bottom: 0 !important;
    // // }

    min-height: 0;
    width: 100%;
    p {
      display: none;
    }
    .pb-4 {
      padding-bottom: 0 !important;
    }
  }
}
div .v-item-group.theme--light {
  @media (max-width: 1024px) {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    .items {
      width: 48%;
    }
  }
}
.rec-card-cont {
  padding: 64px;
  @media (max-width: 1024px) {
    padding: 1rem !important;
  }
  @media (max-width: 1290px) {
    padding: 10px 30px 6rem;
  }
}

.rec-card h4 {
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  color: #212529;
}
.rec-card p {
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  color: #748494;
  margin-bottom: 0;
  width: 90%;
  text-align: left;
  @media (max-width: 1024px) {
    width: 95%;
  }
}
.black-left a {
  text-decoration: none;
}
.dash-img {
  // height: 86%;
  width: 100%;
  @media (max-width: 768px) {
    height: 100%;
  }
}
.green-cont {
  width: 30%;
  border: solid #28a84a;
  background: #28a84a;
  position: absolute;
  bottom: 0;
  padding: 0.5rem;
  display: flex;
  flex-flow: column;
  align-items: center;

  @media (max-width: 1290px) {
    padding: 0 1.5rem;
  }
}
.green-cont h2 {
  color: white;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
}
.card-sub {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #212529;
  @media (max-width: 1290px) {
    font-size: 0.75rem;
  }
}
.card-cont {
  background: #ffffff;
  border-radius: 5px !important;
  border: 0 !important;
  box-shadow: initial;
  filter: drop-shadow(0px 20px 50px rgba(29, 53, 71, 0.1));
  width: 24rem;
  @media (max-width: 768px) {
    width: 22rem !important;
  }
  @media (max-width: 1290px) and (min-width: 769px) {
    width: 23rem;
  }
}
.card2 {
  padding: 0 6rem 4rem;
}
.btm-half {
  background: linear-gradient(-360deg, #f3f8f9 60%, transparent 40%) no-repeat;
}
.one-img {
  position: absolute;
  bottom: 28px;
  left: 37px;
  font-size: 24px;
}
.icon_ {
  position: relative;
  top: 3px;
  left: 5px;
  @media (max-width: 1024px) {
    top: 0px;
    left: 2px;
  }
}
.left {
  width: 90%;
}
.black-left {
  padding: 2rem 8rem;
  @media (max-width: 1024px) {
    padding: 2rem 2rem;
  }
}
.black-right {
  padding: 2rem 6rem;
  @media (max-width: 1024px) {
    padding: 2rem 1rem;
  }
}
.popular {
  position: relative;
  height: 100%;
}
.first {
  height: 29.3rem;
  background: linear-gradient(
    180.14deg,
    rgba(229, 235, 237, 0.1) 15.08%,
    #e5ebed 72.2%,
    #ecfeef 117.33%
  );
  position: relative;
  @media (max-width: 1024px) {
    height: 86.3rem;
  }
}
.second {
  height: 15rem;
  position: relative;
  background: rgba(255, 255, 255);
  @media (max-width: 1024px) {
    background: inherit;
    height: 12rem;
  }
}
.pop {
  position: absolute;
  top: 0;
  width: 100%;
}
.popular-head {
  @media (max-width: 767px) {
    background: #f6f8f9;
  }
}
.head-padding {
  padding-bottom: 16px !important;
  @media (max-width: 1024px) {
    padding-bottom: 2px;
  }
}
.v-card__title {
  word-break: inherit;
}
.v-title-card {
  font-weight: bold;
  font-size: 20px;
  line-height: 140.7%;
  color: #212529;
  @media (max-width: 1290px) {
    font-size: 16px;
    margin-top: -14px;
  }
}
.stroke {
  @media (max-width: 1290px) and (min-width: 770px) {
    margin-bottom: 2px;
  }
}

.heading-title {
  @media (max-width: 1290px) and (min-width: 770px) {
    margin: 2rem auto 1rem;
  }
}

// ::-webkit-scrollbar {
//   height: 4px;              /* height of horizontal scrollbar ← You're missing this */
//   width: 1px;               /* width of vertical scrollbar */
//   border: 1px solid #d5d5d5;
// }

/* width */
::-webkit-scrollbar {
  // width: 2px;
  height: 0%;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: red;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #b30000;
}

.container_ {
  position: relative;
}

.overlay {
  position: absolute;
  bottom: 0rem;
  background: black;
  background: rgba(0, 0, 0, 0.4);
  width: 100%;
  transition: 0.5s ease;
  opacity: 1;
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  h2 {
    color: white;
  }
}

.container_ .overlay {
  opacity: 1;
}
.trapezoid {
  // border-bottom: 18rem solid #555;
  border-left: 25px solid transparent;
  /* border-right: 25px solid transparent; */
  height: 100%;
  width: 100%;
}
.trapezium {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 0;
  width: 150px;
  border-bottom: 150px solid green;
  border-left: 100px solid transparent;
  border-right: 100px solid transparent;
  background: red;
}
.triangle {
  width: 100%;
  height: 100%;
  background-size: cover;
  clip-path: polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%);
  background: black;
  padding-left: 0.7rem;
  @media (max-width: 768px) {
    clip-path: polygon(25% 0%, 100% 0%, 100% 100%, 0% 100%);
    padding-left: initial;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.left-caro {
  display: flex;
  flex-flow: column;
  justify-content: center;
  height: 100%;
  @media (max-width: 1024px) {
    // margin-bottom: 2rem;
    height: auto;
  }
}
.video-row {
  // background: rgba(249, 249, 249, 1);
  height: 28rem;
  position: relative;

  @media (max-width: 1024px) {
    height: auto;
    // min-height: 47rem;
    // padding: 3rem 1rem !important;
    padding: 3rem 0rem 0 !important;
  }
}
.jVcDym {
  height: 100%;
}
.section-head.popular {
  @media (max-width: 768px) {
    height: 8rem !important;
  }
}
.top-btn {
  background-color: rgb(40, 168, 74);
  width: fit-content;
  color: white;
  border-color: rgb(40, 168, 74);
  font-size: 12px;
  position: relative;
  top: 2rem;
  @media (max-width: 768px) {
    top: 0rem;
  }
}
.right-caro {
  @media (min-width: 769px) {
    position: absolute;
    right: -8.6rem;
    background: linear-gradient(90deg, transparent 50%, #28a84a 60% 50%);
  }
}
@media (min-width: 768px) {
  .left-caro ::v-deep .v-window__next {
    right: 21rem !important;
    display: inherit;
  }
}
.left-caro ::v-deep .v-window__next,
.left-caro ::v-deep .v-window__prev {
  background: rgb(98 212 54 / 40%);
  margin-top: 1.5rem;
  @media (max-width: 768px) {
    margin-top: 2.5rem;
  }
  // z-index:10
}
::v-deep .cWExZE img {
  height: auto;
}
.first-btn ::v-deep .v-btn__content {
  font-weight: bolder !important;
}
</style>
