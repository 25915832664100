import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

// const Home = () =>
//   import(
//     /* webpackPreload: true */ /* webpackChunkName: "Home" */ '../views/Home.vue'
//   );

const routes = [
  {
    path: "/",
    redirect: { name: "Home" },
    name: "Container",
    component: () => import("../Container/Container.vue"),
    children: [
      {
        path: "/about",
        name: "About",
        redirect: "/about/our-story",
        component: () =>
          import(
            /* webpackPreload: true */ /* webpackChunkName: "thecontainerAbuou" */ "../views/AboutUS/Container.vue"
          ),
        children: [
          // {
          //   path: '',
          //   component: () => import('../views/AboutUS/About.vue'),
          // },
          {
            path: "our-story",
            component: () =>
              import(
                /* webpackPreload: true */ /* webpackChunkName: "OurStory" */ "../views/AboutUS/About.vue"
              ),
          },
          {
            path: "case-studies",
            component: () =>
              import(
                /* webpackPreload: true */ /* webpackChunkName: "Case" */ "../views/AboutUS/CaseStudy/CaseStudy.vue"
              ),
          },
          {
            path: "case-studies-1",
            component: () =>
              import(
                /* webpackPreload: true */ /* webpackChunkName: "Case1" */ "../views/AboutUS/CaseStudy/Case2.vue"
              ),
          },
          {
            path: "case-studies-2",
            component: () =>
              import(
                /* webpackPreload: true */ /* webpackChunkName: "Case2" */ "../views/AboutUS/CaseStudy/Case3.vue"
              ),
          },
        ],
      },
      {
        path: "/cold-chain-application",
        name: "CaseContainer",
        redirect: "/cold-chain-application/healthcare",
        component: () =>
          import(
            /* webpackPreload: true */ /* webpackChunkName: "thecontainerApp" */ "../views/Application/Container.vue"
          ),
        children: [
          {
            path: "healthcare",
            name: "Case",
            component: () =>
              import(
                /* webpackPreload: true */ /* webpackChunkName: "Healthcare" */ "../views/Application/HealthCare.vue"
              ),
          },
          {
            path: "agriculture",
            name: "Case2",
            component: () =>
              import(
                /* webpackPreload: true */ /* webpackChunkName: "agriculture" */ "../views/Application/Agriculture.vue"
              ),
          },
          {
            path: "logistics",
            name: "Case3",
            component: () =>
              import(
                /* webpackPreload: true */ /* webpackChunkName: "logistics" */ "../views/Application/Logistics.vue"
              ),
          },
        ],
      },

      {
        path: "/cold-chain-equipments",
        name: "Product",
        component: () =>
          import(
            /* webpackPreload: true */ /* webpackChunkName: "thecontainerProduct" */ "../views/Product/Container.vue"
          ),
        children: [
          {
            path: "gricd-mote",
            component: () =>
              import(
                /* webpackPreload: true */ /* webpackChunkName: "gricdMote" */ "../views/Product/GricdMote.vue"
              ),
          },
          {
            path: "mote-50",
            component: () =>
              import(
                /* webpackPreload: true */ /* webpackChunkName: "Mote50" */ "../views/Product/Mote50.vue"
              ),
          },
          {
            path: "",
            name: "cold-chain",
            component: () =>
              import(
                /* webpackPreload: true */ /* webpackChunkName: "cold-chain" */ "../views/Product/Product.vue"
              ),
          },
        ],
      },
      {
        path: "/blog",
        name: "Blog",
        // redirect: '/application/healthcare',
        component: () =>
          import(
            /* webpackPreload: true */ /* webpackChunkName: "thecontainerblog" */ "../views/Blog/Container.vue"
          ),
        children: [
          {
            path: "",
            name: "blog",
            component: () =>
              import(
                /* webpackPreload: true */ /* webpackChunkName: "blog" */ "../views/Blog/Blog.vue"
              ),
          },
          {
            path: "technology-firm-introduces-healthcare-product",
            name: "blog1",
            component: () =>
              import(
                /* webpackPreload: true */ /* webpackChunkName: "blog1" */ "../views/Blog/Blog1.vue"
              ),
          },
          {
            path:
              "the-brilliance-of-nigeria’s-gricd-frij-on-eliminating-food-and-drug-wastes",
            name: "blog2",
            component: () =>
              import(
                /* webpackPreload: true */ /* webpackChunkName: "blog2" */ "../views/Blog/Blog2.vue"
              ),
          },
          {
            path: "curbing-losses-in-the-food-value-chain-in-africa",
            name: "blog3",
            component: () =>
              import(
                /* webpackPreload: true */ /* webpackChunkName: "blog3" */ "../views/Blog/Blog3.vue"
              ),
          },
          {
            path:
              "mote-temperature-monitoring-device-used-to-store-vaccines-for-immunization-services-in-nigeria",
            name: "blog4",
            component: () =>
              import(
                /* webpackPreload: true */ /* webpackChunkName: "blog4" */ "../views/Blog/Blog4.vue"
              ),
          },
          {
            path:
              "reduce-the-frequency-of-vaccine-waste-with-a-new-and-tested-cold-chain-equipment",
            name: "blog5",
            component: () =>
              import(
                /* webpackPreload: true */ /* webpackChunkName: "blog5" */ "../views/Blog/Blog5.vue"
              ),
          },
          {
            path:
              "the-secret-of-covid-19-vaccine-preservation:-health-workers-adopt-new-cold-chain-equipment-to-store-vaccines-at-appropriate-temperatures",
            name: "blog6",
            component: () =>
              import(
                /* webpackPreload: true */ /* webpackChunkName: "blog6" */ "../views/Blog/Blog6.vue"
              ),
          },
          {
            path:
              "interview-managing-director-of-kesona-eye-centre-speaks-about-glaucoma-and-its-impact-on-the-health-of-nigerians",
            name: "blog7",
            component: () =>
              import(
                /* webpackPreload: true */ /* webpackChunkName: "blog7" */ "../views/Blog/Blog7.vue"
              ),
          },
          {
            path:
              "dr-rose-azuike-speaks-about-glaucoma-and-its-effects-on-the-lives-of-nigerians",
            name: "blog8",
            component: () =>
              import(
                /* webpackPreload: true */ /* webpackChunkName: "8" */ "../views/Blog/Blog8.vue"
              ),
          },
          {
            path:
              "gricd-tracks-4.2millions-doses-of-moderna-vaccines-for-the-nigerian-government",
            name: "blog9",
            component: () =>
              import(
                /* webpackPreload: true */ /* webpackChunkName: "blog9" */ "../views/Blog/Blog9.vue"
              ),
          },
          {
            path:
              "digitalisation-in-the-retail-pharmaceutical-sector-shelf-life-director-of-pharmacy-suleman-Sule-and-mPharmas-joyce-aderinlewo-talked-about-how-they-are-helping-thousands-of-community-pharmacies-use-data-and-technology-to-optimise-operations",
            name: "blog10",
            component: () =>
              import(
                /* webpackPreload: true */ /* webpackChunkName: "blog10" */ "../views/Blog/Blog10.vue"
              ),
          },
          {
            path:
              "pharmacist-oyce-aderinlewo-speaks-to-carriers-of-life-on-issues-affecting-the-efficiency-of-community-pharmacies-in-africa",
            name: "blog11",
            component: () =>
              import(
                /* webpackPreload: true */ /* webpackChunkName: "blog11" */ "../views/Blog/Blog11.vue"
              ),
          },
          {
            path:
              "gricd-works-with-isn-to-assure-product-integrity-and-avert-waste-risk",
            name: "blog12",
            component: () =>
              import(
                /* webpackPreload: true */ /* webpackChunkName: "blog12" */ "../views/Blog/Blog12.vue"
              ),
          },
          {
            path:
              "medical-consultant-speaks-on-the-role-of-storage-in-managing-diabetes",
            name: "blog13",
            component: () =>
              import(
                /* webpackPreload: true */ /* webpackChunkName: "blog13" */ "../views/Blog/Blog13.vue"
              ),
          },
          {
            path:
              "could-poor-storage-be-contributing-to-the-high-diabetes-cases-in-africa-urban-areas",
            name: "blog14",
            component: () =>
              import(
                /* webpackPreload: true */ /* webpackChunkName: "blog14" */ "../views/Blog/Blog14.vue"
              ),
          },
          {
            path: "iot-and-ai/ml-in-pharmaceutical-process",
            name: "blog15",
            component: () =>
              import(
                /* webpackPreload: true */ /* webpackChunkName: "blog15" */ "../views/Blog/Blog15.vue"
              ),
          },
          {
            path:
              "smart-technologies-in-the-african-medical-sector-Increasing-the-adoption-rate",
            name: "blog16",
            component: () =>
              import(
                /* webpackPreload: true */ /* webpackChunkName: "blog16" */ "../views/Blog/Blog16.vue"
              ),
          },
          {
            path: "making-logistics-smarter-with-iot-and-ai/ml",
            name: "blog17",
            component: () =>
              import(
                /* webpackPreload: true */ /* webpackChunkName: "blog17" */ "../views/Blog/Blog17.vue"
              ),
          },
          {
            path: "applications-of-medical-marijuana",
            name: "blog18",
            component: () =>
              import(
                /* webpackPreload: true */ /* webpackChunkName: "blog18" */ "../views/Blog/Blog18.vue"
              ),
          },
          {
            path: "the-role-of-storage-in-lab-operation",
            name: "blog19",
            component: () =>
              import(
                /* webpackPreload: true */ /* webpackChunkName: "blog19" */ "../views/Blog/Blog19.vue"
              ),
          },
          {
            path: "the-danger-of-impotent-vaccines",
            name: "blog20",
            component: () =>
              import(
                /* webpackPreload: true */ /* webpackChunkName: "blog20" */ "../views/Blog/Blog20.vue"
              ),
          },
        ],
      },
      {
        path: "/contact",
        name: "container",
        redirect: "contact/contact-us",
        component: () =>
          import(
            /* webpackPreload: true */ /* webpackChunkName: "thecontainerContact" */ "../views/Contact/Container.vue"
          ),
        children: [
          {
            path: "contact-us",
            name: "contact",
            component: () =>
              import(
                /* webpackPreload: true */ /* webpackChunkName: "contact" */ "../views/Contact/Contact.vue"
              ),
          },
          {
            path: "demo-request",
            name: "demo-request",
            component: () =>
              import(
                /* webpackPreload: true */ /* webpackChunkName: "demo" */ "../views/Contact/Demo2.vue"
              ),
          },
        ],
      },
      {
        path: "/demo-request-success",
        name: "demoSuccess",
        component: () =>
          import(
            /* webpackPreload: true */ /* webpackChunkName: "demoSucess" */ "../views/Success.vue"
          ),
      },
      {
        path: "/",
        name: "Home",
        component: Home,
        alias: "/",
        props: true,
      },
      {
        path: "/privacy",
        name: "Privacy",
        component: () =>
          import(
            /* webpackPreload: true */ /* webpackChunkName: "Privacy" */ "../views/Privacy&Terms/Privacy.vue"
          ),
      },
      {
        path: "/terms&conditions",
        name: "Terms",
        component: () =>
          import(
            /* webpackPreload: true */ /* webpackChunkName: "Privacy" */ "../views/Privacy&Terms/Terms&Condition.vue"
          ),
      },
    ],
  },
  {
    path: "*",
    redirect: { name: "Home" },
  },
];

const scrollBehavior = (to, from, savedPosition) => {
  if (savedPosition) {
    return savedPosition;
  } else {
    return { x: 0, y: 0 };
  }
};

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  // scrollBehavior () {
  //   return { x: 0, y: 0 }
  // }
  scrollBehavior,
});

export default router;
