import Vue from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import router from './router';
import VueGtag from 'vue-gtag-conversion';
// import * as VueGoogleMaps from 'vue2-google-maps';
// import VueToast from 'vue-toast-notification';
// import VueMeta from 'vue-meta';
import 'material-design-icons-iconfont/dist/material-design-icons.css';
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
// import 'vue-toast-notification/dist/theme-sugar.css';

// Vue.use(VueToast, {
//   position: 'top-right',
//   duration: 3000,
//   dismissible: true,
// });

Vue.config.productionTip = false;

// Vue.use(VueGoogleMaps, {
//   load: {
//     key: 'AIzaSyDjBtEm5iVkkjqMnrH9LisYBI0Gdpg4P1Y',
//     libraries: 'places',
//   },
// });

// Vue.use(VueTelInputVuetify, {
//   vuetify,
// });

// Vue.use(VueMeta, {refreshOnceOnNavigation: true});

// Vue.use(VueTimepicker);

Vue.use(VueGtag, {
  id: 'AW-385314070/5M8XCLGnyYECEJba3bcB', // Your Adwords ID
  enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: process.env.NODE_ENV !== 'production' (optional)
  debug: true, // Whether or not display console log debugs (optional)
});

Vue.use(VueToast, {
  position: 'top-right',
  duration: 3000,
  dismissible: true,
});

new Vue({
  vuetify,
  router,
  render: (h) => h(App),
}).$mount('#app');
